<template>
  <section>
    <div>
      <h3>Summary:</h3>
    </div>

    <div class="card">
      <div class="card-header">
        <div>
          <strong>Project:</strong> {{ getCurrentProject.name }}
          <b-badge>{{ getCurrentProject.status }}</b-badge>
          <router-link to="/order/new" class="float-right"
            ><b-button>edit</b-button></router-link
          >
        </div>
      </div>
      <div class="card-body">
        <h3>Oversikt ordre nr.12</h3>
        <p class="card-title"><strong>Customer:</strong> Kunde 1 {{}}</p>
        <p><strong>Prosjektnavn:</strong> {{ getCurrentProject.name }}</p>
        <p>
          <strong>Description:</strong>
          <b-form-input v-model="getCurrentProject.description"></b-form-input>
        </p>
        <p><strong>Contact person: </strong>Norman Hansen</p>
        <p>
          <strong>Address: </strong>
          <a class="btn btn-link" target="_blank" href="https://maps.google.com"
            >Gate1</a
          >
        </p>
        <p>
          <strong>Delivery address: </strong>
          <b-form-input
            id="deliveryAddress"
            v-model="getCurrentOrder.deliveryAddress"
          ></b-form-input>
        </p>
        <p>
          <strong>Shipping date:</strong>
          <b-form-input
            id="shippingDate"
            type="date"
            v-model="getCurrentOrder.shippingDate"
          ></b-form-input>
        </p>
        <p>
          <strong>Delivery date:</strong>
          <b-form-input
            id="deliveryDate"
            type="date"
            v-model="getCurrentOrder.deliveryDate"
          ></b-form-input>
        </p>
        <p>
          <strong>Received date:</strong>
          <b-form-input
            id="receivedDate"
            type="date"
            readonly
            v-model="getCurrentOrder.receivedDate"
          ></b-form-input>
        </p>
        <p>
          <router-link to="/order/lines">
            <b-button>Edit order details</b-button>
            <b-button
              variant="success"
              class="ml-3"
              @click="updateCreateCurrentOrder(getCurrentOrder)"
              >Send</b-button
            >
          </router-link>
        </p>
        <hr />
        <b-table
          striped
          hover
          :items="
            getCurrentOrder.orderLines.map(x => ({
              name: x.name,
              height: x.height,
              width: x.width,
              sqrmeter: x.sqrmeter
            }))
          "
        ></b-table>
      </div>

      <b-card-footer>
        <p>Lines: {{ getCurrentOrder.orderLines.length || 0 }}</p>
      </b-card-footer>

      <!-- <b-card class="mt-3"  header="Form Data getCurrentProject">
        <pre class="m-0">{{ getCurrentProject }}</pre>
    </b-card> -->

      <!-- <b-card class="mt-3"  header="Form Data getCurrentOrder">
        <pre class="m-0">{{ getCurrentOrder }}</pre>
    </b-card> -->
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "orderSummary",
  components: {},
  methods: {
    ...mapActions(["updateCreateCurrentOrder"])
  },
  computed: mapGetters(["getCurrentOrder", "getCurrentProject"]),
  created() {
    //defaults delivery address to current project contact details
    // NEEDS review
    if (this.getCurrentOrder.deliveryAddress === null) {
      this.getCurrentOrder.deliveryAddress =
        this.getCurrentProject.contactDetails[0].address || "Missing address";
    }
  },
  data() {
    return {};
  }
};
</script>

<style></style>
